// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {

}

.stats {
    padding-top: 8px;
    max-width: 300px;
    /* width: 200px; */
    /*padding: 10px;*/
    /*gap: 8px;*/
    /*margin: 2px;*/
}
`, "",{"version":3,"sources":["webpack://./src/Components/Dashboards/CardProductionLine/CardProductionLine.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".header {\n\n}\n\n.stats {\n    padding-top: 8px;\n    max-width: 300px;\n    /* width: 200px; */\n    /*padding: 10px;*/\n    /*gap: 8px;*/\n    /*margin: 2px;*/\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
