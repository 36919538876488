// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Container_Container__bNH9Y {
    background-color: #f7f7f7;
    padding: 20px;
    border-radius: 1em;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    height: auto;
    width: auto;
}

.Container_Container__bNH9Y.Container_light__TYZZ- {
    background: #fff;
}


.Container_Container__bNH9Y.Container_dark__3FzzG {
    background-color: #1e1e1e;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Common/Container/Container.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,uCAAuC;IACvC,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB;;;AAGA;IACI,yBAAyB;AAC7B","sourcesContent":[".Container {\n    background-color: #f7f7f7;\n    padding: 20px;\n    border-radius: 1em;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    display: flex;\n    flex-direction: column;\n    height: auto;\n    width: auto;\n}\n\n.Container.light {\n    background: #fff;\n}\n\n\n.Container.dark {\n    background-color: #1e1e1e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": `Container_Container__bNH9Y`,
	"light": `Container_light__TYZZ-`,
	"dark": `Container_dark__3FzzG`
};
export default ___CSS_LOADER_EXPORT___;
