// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProgressBar_progressBarContainer__Ri8Lb {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 5px;
    /*padding: 4px;*/
    /*background-color: #f3f3f3;*/
    background-color: #e9e9e9;
    /*background-color: #299cff;*/
    border-radius: 5px;
    overflow: hidden;
}

.ProgressBar_progressBar__ulx9j {

    height: 10px;
    background-color: #4caf50;
    text-align: center;
    line-height: 30px;
    color: white;
    transition: width 0.3s;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Common/ProgressBar/ProgressBar.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,6BAA6B;IAC7B,yBAAyB;IACzB,6BAA6B;IAC7B,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;;IAEI,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;IACZ,sBAAsB;AAC1B","sourcesContent":[".progressBarContainer {\n    width: 100%;\n    margin-top: 8px;\n    margin-bottom: 5px;\n    /*padding: 4px;*/\n    /*background-color: #f3f3f3;*/\n    background-color: #e9e9e9;\n    /*background-color: #299cff;*/\n    border-radius: 5px;\n    overflow: hidden;\n}\n\n.progressBar {\n\n    height: 10px;\n    background-color: #4caf50;\n    text-align: center;\n    line-height: 30px;\n    color: white;\n    transition: width 0.3s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressBarContainer": `ProgressBar_progressBarContainer__Ri8Lb`,
	"progressBar": `ProgressBar_progressBar__ulx9j`
};
export default ___CSS_LOADER_EXPORT___;
