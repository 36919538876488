// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Preloader_container__01NJp {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Preloader_spinner__JyW4m {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: Preloader_spin__yJ14m 1s linear infinite;
}

.Preloader_spinner__JyW4m.Preloader_light__iNTzv {
    border-top: 10px solid #87a3c2;
}

.Preloader_spinner__JyW4m.Preloader_dark__GsL0W {
    border-top: 10px solid #c3cfe2;
}



@keyframes Preloader_spin__yJ14m {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Common/Preloader/Preloader.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;AACpC;;AAEA;IACI,0BAA0B;IAC1B,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,mDAAkC;AACtC;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,8BAA8B;AAClC;;;;AAIA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".container {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n}\n\n.spinner {\n    border: 10px solid #f3f3f3;\n    border-radius: 50%;\n    width: 80px;\n    height: 80px;\n    animation: spin 1s linear infinite;\n}\n\n.spinner.light {\n    border-top: 10px solid #87a3c2;\n}\n\n.spinner.dark {\n    border-top: 10px solid #c3cfe2;\n}\n\n\n\n@keyframes spin {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Preloader_container__01NJp`,
	"spinner": `Preloader_spinner__JyW4m`,
	"spin": `Preloader_spin__yJ14m`,
	"light": `Preloader_light__iNTzv`,
	"dark": `Preloader_dark__GsL0W`
};
export default ___CSS_LOADER_EXPORT___;
