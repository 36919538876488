// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TopBar_topBar__M7S92 {

    padding: 16px;
    position: fixed;
    display: flex;
    flex-direction: row;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}
.TopBar_topBar__M7S92.TopBar_light__eTkZR {
    background-color: #ddd;
    color: #fff;
}

.TopBar_topBar__M7S92.TopBar_dark__NkNud {
    background-color: #1e1e1e;
}

.TopBar_container__iDGpd {
    display: flex;
    flex-direction: row;
    text-align: start;
}

.TopBar_user_container__tWwQ4 {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: end;
    margin-left: auto;
    margin-right: 40px;
}
/*
@media (max-width: 768px) {
    .topBar {
        flex-direction: column;
    }
    .topBar >.container {
        flex-wrap: wrap;
    }
    .topBar >.container > * {
        width: 100%;
    }

    .user_container {
        text-align: start;
        flex-direction: row;
        align-items: flex-start;
    }
    .topBar >.user_container {
        flex-wrap: wrap;
    }
    .topBar >.user_container > * {
        width: 100%;
    }
} */

`, "",{"version":3,"sources":["webpack://./src/Components/TopBar/TopBar.module.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,MAAM;IACN,OAAO;IACP,WAAW;IACX,aAAa;AACjB;AACA;IACI,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;AACA;;;;;;;;;;;;;;;;;;;;;;;GAuBG","sourcesContent":[".topBar {\n\n    padding: 16px;\n    position: fixed;\n    display: flex;\n    flex-direction: row;\n    top: 0;\n    left: 0;\n    width: 100%;\n    z-index: 1000;\n}\n.topBar.light {\n    background-color: #ddd;\n    color: #fff;\n}\n\n.topBar.dark {\n    background-color: #1e1e1e;\n}\n\n.container {\n    display: flex;\n    flex-direction: row;\n    text-align: start;\n}\n\n.user_container {\n    display: flex;\n    flex-direction: row;\n    gap: 20px;\n    align-items: end;\n    margin-left: auto;\n    margin-right: 40px;\n}\n/*\n@media (max-width: 768px) {\n    .topBar {\n        flex-direction: column;\n    }\n    .topBar >.container {\n        flex-wrap: wrap;\n    }\n    .topBar >.container > * {\n        width: 100%;\n    }\n\n    .user_container {\n        text-align: start;\n        flex-direction: row;\n        align-items: flex-start;\n    }\n    .topBar >.user_container {\n        flex-wrap: wrap;\n    }\n    .topBar >.user_container > * {\n        width: 100%;\n    }\n} */\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topBar": `TopBar_topBar__M7S92`,
	"light": `TopBar_light__eTkZR`,
	"dark": `TopBar_dark__NkNud`,
	"container": `TopBar_container__iDGpd`,
	"user_container": `TopBar_user_container__tWwQ4`
};
export default ___CSS_LOADER_EXPORT___;
