// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StoragePieDiagram_diagram-container__YW2Yf {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    /*height: 100%; !* or set a specific height *!*/
    /*width: 100%;*/
    max-height: 268px;
    max-width: 268px;
}

.StoragePieDiagram_centered-div__FMdKn {
    margin: 0 auto;
    min-width: 250px;
    display: inline-block
    /*width: fit-content; !* or set a specific width *!*/
}
`, "",{"version":3,"sources":["webpack://./src/Components/Dashboards/StoragePieDiagram/StoragePieDiagram.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,+CAA+C;IAC/C,eAAe;IACf,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,oBAAoB;IACpB,oDAAoD;AACxD","sourcesContent":[".diagram-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    box-sizing: border-box;\n    /*height: 100%; !* or set a specific height *!*/\n    /*width: 100%;*/\n    max-height: 268px;\n    max-width: 268px;\n}\n\n.centered-div {\n    margin: 0 auto;\n    min-width: 250px;\n    display: inline-block\n    /*width: fit-content; !* or set a specific width *!*/\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"diagram-container": `StoragePieDiagram_diagram-container__YW2Yf`,
	"centered-div": `StoragePieDiagram_centered-div__FMdKn`
};
export default ___CSS_LOADER_EXPORT___;
