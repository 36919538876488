// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SwitchThemeButton_SwitchThemeButton__ge5wT {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 26px;
    background-color: #333;
    border-radius: 20px;
    cursor: pointer;

}

.SwitchThemeButton_SwitchThemeButton__ge5wT input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
}

.SwitchThemeButton_slider__yVOvG {
    position: absolute;
    left: 2px;
    top: 2px;
    width: 22px;
    height: 22px;
    background-color: #333;
    border-radius: 50%;
    transition: 0.4s;
}

.SwitchThemeButton_SwitchThemeButton__ge5wT.SwitchThemeButton_dark__J6voK {
    background-color: #fff;
}

.SwitchThemeButton_SwitchThemeButton__ge5wT.SwitchThemeButton_dark__J6voK .SwitchThemeButton_slider__yVOvG {
    background-color: #333;
    transform: translateX(24px);
}

.SwitchThemeButton_SwitchThemeButton__ge5wT.SwitchThemeButton_light__eVIoA .SwitchThemeButton_slider__yVOvG {
    background-color: #fff;
}

`, "",{"version":3,"sources":["webpack://./src/Components/Sidebar/SwitchThemeButton/SwitchThemeButton.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;;AAEnB;;AAEA;IACI,UAAU;IACV,QAAQ;IACR,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;IACtB,2BAA2B;AAC/B;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".SwitchThemeButton {\n    display: inline-block;\n    position: relative;\n    width: 50px;\n    height: 26px;\n    background-color: #333;\n    border-radius: 20px;\n    cursor: pointer;\n\n}\n\n.SwitchThemeButton input[type=\"checkbox\"] {\n    opacity: 0;\n    width: 0;\n    height: 0;\n}\n\n.slider {\n    position: absolute;\n    left: 2px;\n    top: 2px;\n    width: 22px;\n    height: 22px;\n    background-color: #333;\n    border-radius: 50%;\n    transition: 0.4s;\n}\n\n.SwitchThemeButton.dark {\n    background-color: #fff;\n}\n\n.SwitchThemeButton.dark .slider {\n    background-color: #333;\n    transform: translateX(24px);\n}\n\n.SwitchThemeButton.light .slider {\n    background-color: #fff;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SwitchThemeButton": `SwitchThemeButton_SwitchThemeButton__ge5wT`,
	"slider": `SwitchThemeButton_slider__yVOvG`,
	"dark": `SwitchThemeButton_dark__J6voK`,
	"light": `SwitchThemeButton_light__eVIoA`
};
export default ___CSS_LOADER_EXPORT___;
