// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DataLabel_data_label__1ixDU {
    font-size: 12px;
    padding-top: 4px;
    display: flex;
    flex-direction: row;
}
.DataLabel_data_label__1ixDU.DataLabel_light__ToKRL {
    color: #1a1a1a;
}
.DataLabel_data_label__1ixDU.DataLabel_dark__DNVNm {
    color: white;
}

.DataLabel_label__gXsnS {
    font-weight: bold;
}



.DataLabel_value__-VwH9 {
    align-items: end;
    margin-left: auto;
    /*margin-right: 20px;*/
}
`, "",{"version":3,"sources":["webpack://./src/Components/Dashboards/CardProductionLine/DataLabel/DataLabel.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,cAAc;AAClB;AACA;IACI,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB;;;;AAIA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;AAC1B","sourcesContent":[".data_label {\n    font-size: 12px;\n    padding-top: 4px;\n    display: flex;\n    flex-direction: row;\n}\n.data_label.light {\n    color: #1a1a1a;\n}\n.data_label.dark {\n    color: white;\n}\n\n.label {\n    font-weight: bold;\n}\n\n\n\n.value {\n    align-items: end;\n    margin-left: auto;\n    /*margin-right: 20px;*/\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"data_label": `DataLabel_data_label__1ixDU`,
	"light": `DataLabel_light__ToKRL`,
	"dark": `DataLabel_dark__DNVNm`,
	"label": `DataLabel_label__gXsnS`,
	"value": `DataLabel_value__-VwH9`
};
export default ___CSS_LOADER_EXPORT___;
