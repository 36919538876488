// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Text_text__FJ0Jf {
    /*align-content: center;*/
}

.Text_text__FJ0Jf.Text_light__RekGT {
    color: black
}

.Text_text__FJ0Jf.Text_dark__lA-P6 {
    color: white;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Common/Text/Text.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI;AACJ;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".text {\n    /*align-content: center;*/\n}\n\n.text.light {\n    color: black\n}\n\n.text.dark {\n    color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `Text_text__FJ0Jf`,
	"light": `Text_light__RekGT`,
	"dark": `Text_dark__lA-P6`
};
export default ___CSS_LOADER_EXPORT___;
