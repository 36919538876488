// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonBar_Button__RZOj9 {
    font-size: x-large;
    padding: 0.8rem 1.5rem;
    /*border: 1px solid rgb(0, 0, 0);*/
    border-radius: 1rem;
    /*margin-top: 25px;*/
    cursor: pointer;
    text-decoration: none;
    color: #000000;
    text-align: start;
}

.ButtonBar_Button__RZOj9:disabled{
    opacity: 0.5;
    cursor: not-allowed;
}

.ButtonBar_Button__RZOj9.ButtonBar_light__VNlgJ:hover {
    /*background: #333333;*/
    color: #3965FF;
}

.ButtonBar_Button__RZOj9.ButtonBar_dark__EgIoC:hover {
    color: #333333;
    /*background: #ffffff;*/
}

.ButtonBar_Button__RZOj9.ButtonBar_dark__EgIoC {
    color: #ffffff;
    background-color: rgba(255, 0, 0, 0);
    border-color: white;
}

.ButtonBar_Button__RZOj9.ButtonBar_light__VNlgJ.ButtonBar_active__XDJFE {
    color: #299cff;
}

.ButtonBar_Button__RZOj9.ButtonBar_dark__EgIoC.ButtonBar_active__XDJFE {
    color: #296aff;
}

.ButtonBar_active__XDJFE {
    color: red;
}

/*:global(.active) {*/

/*}*/

/*.Button.light {*/
/*    color: #333333;*/
/*    background-color: rgba(255, 0, 0, 0);*/
/*    text-decoration: none;*/
/*}*/
`, "",{"version":3,"sources":["webpack://./src/Components/TopBar/ButtonBar/ButtonBar.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,kCAAkC;IAClC,mBAAmB;IACnB,oBAAoB;IACpB,eAAe;IACf,qBAAqB;IACrB,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;IACvB,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,uBAAuB;AAC3B;;AAEA;IACI,cAAc;IACd,oCAAoC;IACpC,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,UAAU;AACd;;AAEA,qBAAqB;;AAErB,IAAI;;AAEJ,kBAAkB;AAClB,sBAAsB;AACtB,4CAA4C;AAC5C,6BAA6B;AAC7B,IAAI","sourcesContent":[".Button {\n    font-size: x-large;\n    padding: 0.8rem 1.5rem;\n    /*border: 1px solid rgb(0, 0, 0);*/\n    border-radius: 1rem;\n    /*margin-top: 25px;*/\n    cursor: pointer;\n    text-decoration: none;\n    color: #000000;\n    text-align: start;\n}\n\n.Button:disabled{\n    opacity: 0.5;\n    cursor: not-allowed;\n}\n\n.Button.light:hover {\n    /*background: #333333;*/\n    color: #3965FF;\n}\n\n.Button.dark:hover {\n    color: #333333;\n    /*background: #ffffff;*/\n}\n\n.Button.dark {\n    color: #ffffff;\n    background-color: rgba(255, 0, 0, 0);\n    border-color: white;\n}\n\n.Button.light.active {\n    color: #299cff;\n}\n\n.Button.dark.active {\n    color: #296aff;\n}\n\n.active {\n    color: red;\n}\n\n/*:global(.active) {*/\n\n/*}*/\n\n/*.Button.light {*/\n/*    color: #333333;*/\n/*    background-color: rgba(255, 0, 0, 0);*/\n/*    text-decoration: none;*/\n/*}*/\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Button": `ButtonBar_Button__RZOj9`,
	"light": `ButtonBar_light__VNlgJ`,
	"dark": `ButtonBar_dark__EgIoC`,
	"active": `ButtonBar_active__XDJFE`
};
export default ___CSS_LOADER_EXPORT___;
