// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardEmployee_employeeCard__r5GTS {
    display: flex;
    /*flex-direction: column;*/
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    max-width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.CardEmployee_employeeCard__imageContainer__DrzJP {
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CardEmployee_employeeCard__image__LwMOp {
    width: 150px;
    height: 150px;
    text-align: center;
    margin: 0 auto;
}

.CardEmployee_employeeCard__info__3Pffs {
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.CardEmployee_employeeCard__name__68sYd {
    font-size: 1.2em;
    margin: 0;
}
.CardEmployee_employeeCard__name__68sYd.CardEmployee_dark__Kvujr {
    color: white;
}

.CardEmployee_employeeCard__position__oYv3X {
    color: #777;
    margin: 4px 0;
}

.CardEmployee_employeeCard__accessLevel__Dkhk- {
    font-weight: bold;
    /*color: green;*/
}

.CardEmployee_employeeCard__accessLevel__Dkhk-.CardEmployee_dark__Kvujr {
    color: white;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Dashboards/CardEmployee/CardEmployee.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,0BAA0B;IAC1B,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,wCAAwC;AAC5C;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,SAAS;AACb;AACA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".employeeCard {\n    display: flex;\n    /*flex-direction: column;*/\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    overflow: hidden;\n    max-width: 400px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.employeeCard__imageContainer {\n    width: 150px;\n    height: 150px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.employeeCard__image {\n    width: 150px;\n    height: 150px;\n    text-align: center;\n    margin: 0 auto;\n}\n\n.employeeCard__info {\n    padding: 12px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n}\n\n.employeeCard__name {\n    font-size: 1.2em;\n    margin: 0;\n}\n.employeeCard__name.dark {\n    color: white;\n}\n\n.employeeCard__position {\n    color: #777;\n    margin: 4px 0;\n}\n\n.employeeCard__accessLevel {\n    font-weight: bold;\n    /*color: green;*/\n}\n\n.employeeCard__accessLevel.dark {\n    color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"employeeCard": `CardEmployee_employeeCard__r5GTS`,
	"employeeCard__imageContainer": `CardEmployee_employeeCard__imageContainer__DrzJP`,
	"employeeCard__image": `CardEmployee_employeeCard__image__LwMOp`,
	"employeeCard__info": `CardEmployee_employeeCard__info__3Pffs`,
	"employeeCard__name": `CardEmployee_employeeCard__name__68sYd`,
	"dark": `CardEmployee_dark__Kvujr`,
	"employeeCard__position": `CardEmployee_employeeCard__position__oYv3X`,
	"employeeCard__accessLevel": `CardEmployee_employeeCard__accessLevel__Dkhk-`
};
export default ___CSS_LOADER_EXPORT___;
