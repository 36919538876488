// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonSettings_container__g1fyL {
    position: relative;
    display: inline-block;
}

.ButtonSettings_button__yY-7f {
    background: none;
    border: none;
    cursor: pointer;
}

.ButtonSettings_menu__c\\+JSQ {
    position: absolute;
    /*top: 100%; !* позиционирование чуть ниже кнопки *!*/
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 80px;
}

.ButtonSettings_menuList__i-z37 {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.ButtonSettings_menuItem__bee5p {
    padding: 6px;
}

.ButtonSettings_menuItem__bee5p a {
    text-decoration: none;
    color: black;
    display: block;
}

.ButtonSettings_menuItem__bee5p a:hover {
    background-color: #f0f0f0;
}
`, "",{"version":3,"sources":["webpack://./src/Components/TopBar/ButtonSettings/ButtonSettings.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,qDAAqD;IACrD,OAAO;IACP,uBAAuB;IACvB,sBAAsB;IACtB,yCAAyC;IACzC,aAAa;IACb,WAAW;AACf;;AAEA;IACI,qBAAqB;IACrB,SAAS;IACT,UAAU;AACd;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,qBAAqB;IACrB,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".container {\n    position: relative;\n    display: inline-block;\n}\n\n.button {\n    background: none;\n    border: none;\n    cursor: pointer;\n}\n\n.menu {\n    position: absolute;\n    /*top: 100%; !* позиционирование чуть ниже кнопки *!*/\n    left: 0;\n    background-color: white;\n    border: 1px solid #ccc;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n    z-index: 1000;\n    width: 80px;\n}\n\n.menuList {\n    list-style-type: none;\n    margin: 0;\n    padding: 0;\n}\n\n.menuItem {\n    padding: 6px;\n}\n\n.menuItem a {\n    text-decoration: none;\n    color: black;\n    display: block;\n}\n\n.menuItem a:hover {\n    background-color: #f0f0f0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ButtonSettings_container__g1fyL`,
	"button": `ButtonSettings_button__yY-7f`,
	"menu": `ButtonSettings_menu__c+JSQ`,
	"menuList": `ButtonSettings_menuList__i-z37`,
	"menuItem": `ButtonSettings_menuItem__bee5p`
};
export default ___CSS_LOADER_EXPORT___;
