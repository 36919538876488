// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HeaderStatus_status__4o0YR {
    position: relative;
    display: inline-block;
    padding: 8px;
    border-radius: 8px;
    background-color: gray;
}
.HeaderStatus_status__4o0YR.HeaderStatus_red__-XIix {
    background-color: red;
}
.HeaderStatus_status__4o0YR.HeaderStatus_yellow__jNOgY {
    background-color: yellow;
}
.HeaderStatus_status__4o0YR.HeaderStatus_green__HLOOn {
    background-color: green;
}

.HeaderStatus_status__4o0YR:hover .HeaderStatus_tooltip__SszKv {
    visibility: visible;
    opacity: 1;
}

.HeaderStatus_tooltip__SszKv {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.HeaderStatus_tooltip__SszKv::after {
    content: "";
    position: absolute;
    top: 100%; /* Arrow pointing down */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Dashboards/CardProductionLine/HeaderCard/HeaderStatus/HeaderStatus.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;AAC1B;AACA;IACI,qBAAqB;AACzB;AACA;IACI,wBAAwB;AAC5B;AACA;IACI,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,uBAAuB;IACvB,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,SAAS;IACT,kBAAkB;IAClB,UAAU;IACV,wBAAwB;AAC5B;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,SAAS,EAAE,wBAAwB;IACnC,SAAS;IACT,iBAAiB;IACjB,iBAAiB;IACjB,mBAAmB;IACnB,uDAAuD;AAC3D","sourcesContent":[".status {\n    position: relative;\n    display: inline-block;\n    padding: 8px;\n    border-radius: 8px;\n    background-color: gray;\n}\n.status.red {\n    background-color: red;\n}\n.status.yellow {\n    background-color: yellow;\n}\n.status.green {\n    background-color: green;\n}\n\n.status:hover .tooltip {\n    visibility: visible;\n    opacity: 1;\n}\n\n.tooltip {\n    visibility: hidden;\n    width: 120px;\n    background-color: black;\n    color: #fff;\n    text-align: center;\n    border-radius: 5px;\n    padding: 5px;\n    position: absolute;\n    z-index: 1;\n    bottom: 125%;\n    left: 50%;\n    margin-left: -60px;\n    opacity: 0;\n    transition: opacity 0.3s;\n}\n\n.tooltip::after {\n    content: \"\";\n    position: absolute;\n    top: 100%; /* Arrow pointing down */\n    left: 50%;\n    margin-left: -5px;\n    border-width: 5px;\n    border-style: solid;\n    border-color: black transparent transparent transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": `HeaderStatus_status__4o0YR`,
	"red": `HeaderStatus_red__-XIix`,
	"yellow": `HeaderStatus_yellow__jNOgY`,
	"green": `HeaderStatus_green__HLOOn`,
	"tooltip": `HeaderStatus_tooltip__SszKv`
};
export default ___CSS_LOADER_EXPORT___;
