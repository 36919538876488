// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HeaderCard_HeaderCard__FyYt3 {
    font-size: 18px;
    border-radius: 0.5em;
    padding: 8px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center; /* Центрируем по вертикали */
    justify-content: space-between; /* Размещаем статус в правом краю */
}

.HeaderCard_HeaderCard__FyYt3.HeaderCard_light__2JrWC {
    background-color: #f5f7fa;
}

.HeaderCard_HeaderCard__FyYt3.HeaderCard_dark__r53HA {
    background-color: #2f2f2f;
}


`, "",{"version":3,"sources":["webpack://./src/Components/Dashboards/CardProductionLine/HeaderCard/HeaderCard.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,oBAAoB;IACpB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,mBAAmB,EAAE,4BAA4B;IACjD,8BAA8B,EAAE,mCAAmC;AACvE;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".HeaderCard {\n    font-size: 18px;\n    border-radius: 0.5em;\n    padding: 8px;\n    display: flex;\n    flex-direction: row;\n    gap: 10px;\n    align-items: center; /* Центрируем по вертикали */\n    justify-content: space-between; /* Размещаем статус в правом краю */\n}\n\n.HeaderCard.light {\n    background-color: #f5f7fa;\n}\n\n.HeaderCard.dark {\n    background-color: #2f2f2f;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HeaderCard": `HeaderCard_HeaderCard__FyYt3`,
	"light": `HeaderCard_light__2JrWC`,
	"dark": `HeaderCard_dark__r53HA`
};
export default ___CSS_LOADER_EXPORT___;
