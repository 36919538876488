// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContentContainer_Container__0LxSR {
    box-sizing: border-box;
    align-content: center;
    justify-content: flex-start;
    margin: 100px 20px 20px;

    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    transition: flex-wrap 1.5s;
}

.ContentContainer_wrap__sXS5I:hover {
    flex-wrap: nowrap;
}

/*.Container.light {*/
/*    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);*/
/*}*/

/*.Container.dark {*/
/*    background: linear-gradient(135deg, #1a1a1a 0%, #333333 100%);*/
/*}*/
`, "",{"version":3,"sources":["webpack://./src/Components/Common/ContentContainer/ContentContainer.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,qBAAqB;IACrB,2BAA2B;IAC3B,uBAAuB;;IAEvB,aAAa;IACb,SAAS;IACT,eAAe;IACf,0BAA0B;AAC9B;;AAEA;IACI,iBAAiB;AACrB;;AAEA,qBAAqB;AACrB,qEAAqE;AACrE,IAAI;;AAEJ,oBAAoB;AACpB,qEAAqE;AACrE,IAAI","sourcesContent":[".Container {\n    box-sizing: border-box;\n    align-content: center;\n    justify-content: flex-start;\n    margin: 100px 20px 20px;\n\n    display: flex;\n    gap: 10px;\n    flex-wrap: wrap;\n    transition: flex-wrap 1.5s;\n}\n\n.wrap:hover {\n    flex-wrap: nowrap;\n}\n\n/*.Container.light {*/\n/*    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);*/\n/*}*/\n\n/*.Container.dark {*/\n/*    background: linear-gradient(135deg, #1a1a1a 0%, #333333 100%);*/\n/*}*/\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": `ContentContainer_Container__0LxSR`,
	"wrap": `ContentContainer_wrap__sXS5I`
};
export default ___CSS_LOADER_EXPORT___;
